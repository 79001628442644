<template>
  <SplashIntro />
</template>

<script>
import SplashIntro from "./SplashIntro.vue";

export default {
  name: "App",
  components: {
    SplashIntro,
  },
};
</script>